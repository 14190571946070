/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: "Inter", sans-serif !important;
}

.plt-android:root,
.plt-desktop:root {
  --ion-safe-area-top: 0px !important;
}

:root {
  --ion-safe-area-top: 20px;
  --ion-default-font: "Inter", sans-serif !important;
}
.plt-iphone.plt-ios {
  --ion-safe-area-top: 40px;

  --ion-default-font: "Inter", sans-serif !important;
}
.plt-iphone.plt-ios {
  .top-card {
    padding: 40px 0 5px !important;
    ion-icon {
      position: relative;
      z-index: 99;
      align-self: center;
    }
    .notification {
      align-self: center;
    }
  }
}

body {
  // padding-top: constant(safe-area-inset-top);
  // padding-top: env(safe-area-inset-top);

  // margin-top: constant(safe-area-inset-top);
  // margin-top: env(safe-area-inset-top);
}

.client-filter-modal {
  --height: auto;
  align-items: end;
  --background: transparent;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    border-radius: 55px 55px 0px 0px;
    .inner-content {
      max-height: calc(100vh - 230px);
      overflow: auto;
    }
  }
  ion-footer {
    background: var(--white-bg);
    border-radius: 0;
    ion-toolbar {
      border-radius: 0 !important;
      border: 0;
      background: var(--white-bg);
    }
  }
}

.small-modal {
  --height: auto;
  align-items: center;
  --background: transparent;
  background: transparent;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    border-radius: 32px;
    background: var(--white-bg);
    max-width: 70%;
    margin: 0 auto;
    .inner-content {
      max-height: 70vh;
      overflow: auto;
    }
  }
  ion-footer {
    background: var(--white-bg);
    border-radius: 0;
    ion-toolbar {
      border-radius: 0 !important;
      border: 0;
      background: var(--white-bg);
    }
  }
  &.bid-submitted {
    .ion-page {
      max-width: 76%;
    }
  }
}

ion-item {
  --ripple-color: none;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --ripple-color: none;
}
ion-radio,
ion-checkbox {
  --ripple-color: none;
}

.list-data {
  // ion-list{margin: 0; padding: 0;
  ion-item {
    border-bottom: 1px solid #e3e3e3;
    --padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --innerpadding-end: 0;
    --background: transparent;
    --ripple-color: none;
    --background-activated: none;
    --background-focused: none;
    --background-hover: none;
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --ripple-color: none;
    ion-label {
      h5 {
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        color: var(--text-black) !important;
      }
    }
    img {
      width: 25px !important;
      height: 25px;
      border-radius: 25px;
      margin: 0 10px 0 0;
    }
  }
  // }
  img {
    width: 25px !important;
    height: 25px;
    border-radius: 25px;
    margin: 0 10px 0 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

.auth-form {
  ng-otp-input {
    .optInputContainer {
      display: flex;
      justify-content: center;
      input {
        margin-top: 0px;
        text-align: center;
        padding: 0;
        font-size: 1rem;
        color: var(--text-black);
        background: rgba(var(--white-bg-rgb), 0.7);
        border-radius: 4px;
        border: 1px solid transparent;
        padding: 5px;
        max-width: 40px;
        width: 100%;
        height: 40px;
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
        &.otp-input.otpInput.ng-valid.ng-dirty.ng-touched {
          border: 1px solid transparent;
        }
        &.otp-input.otpInput.ng-valid.ng-dirty.ng-touched {
          border-color: var(--black-clr);
        }
      }
    }
  }
}

.logo-head {
  .nil4all {
    span {
      font-size: 0.875rem;
      color: var(--white-clr);
      // text-transform: uppercase;
      opacity: 1;
      font-weight: 500;
      margin-top: 5px;
      display: inline-block;
      font-style: italic;
    }
  }
}
// ion-app {
//   --ion-safe-area-top: 30px;
// }
ion-modal.tandc-modal-new {
  ion-header {
    --background: #fff;
    ion-toolbar {
      position: relative;
      --background: #fff;
      ion-title {
        align-items: center;
        padding: 0 15px;
        font-size: 1rem;
        color: var(--theme-one-clr);
      }
      ion-icon {
        font-size: 1.5rem;
        color: var(--text-black);
        margin-right: 10px;
      }
    }
  }
  ion-content {
    --background: #fff;
    ion-grid {
      margin: 0;
      padding: 15px;
      p {
        font-size: 0.875rem;
        color: var(--text-black);
        margin: 0 0 10px;
        font-weight: 400;
        text-align: justify;
        line-height: 21px;
      }
    }
  }
}

@media only screen and (max-width: 374px) {
  html {
    font-size: 84%;
  }
}

input:-internal-autofill-selected {
  background: transparent !important;
  color: var(--text-black) !important;
  --color: var(--text-black) !important;
}

.mycomponent-wider-popover {
  ion-item {
    --inner-border-width: 0;
    background: var(--white-bg);
    --background: var(--white-bg);
    --background: var(--white-bg);
    --ripple-color: none;
    --background-activated: var(--white-bg);
    --background-focused: var(--white-bg);
    --background-hover: var(--white-bg);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --min-height: 33px;
    --padding-start: 10px;
    --inner-padding-end: 10px;
    ion-label {
      color: var(--text-gray) !important;
      font-size: 0.875rem !important;
      font-weight: 500;
      padding-left: 30px;
      position: absolute;
      margin: 0;
    }
    ion-radio {
      height: 20px;
      width: 20px;
      border: 2px solid var(--theme-one-clr);
      border-radius: 50px;
      margin: 0;
      .radio-inner {
        width: 28%;
        height: 52%;
      }
    }

    &.item-radio-checked {
      ion-label {
        color: var(--text-black) !important;
      }
      ion-radio {
        background: var(--green-bg);
        --color-checked: var(--white-clr);
      }
    }
  }
}

.list-options {
  .popover-content {
    background: var(--white-bg);
    --background: var(--white-bg);
    border-radius: 10px;
    --border-radius: 10px;
  }
  ion-item {
    --inner-border-width: 0;
    background: var(--white-bg);
    --background: var(--white-bg);
    --background: var(--white-bg);
    --ripple-color: none;
    --background-activated: var(--white-bg);
    --background-focused: var(--white-bg);
    --background-hover: var(--white-bg);
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --min-height: 34px;
    --padding-start: 10px;
    --inner-padding-end: 10px;
    ion-label {
      color: var(--text-gray) !important;
      font-size: 0.875rem !important;
      font-weight: 500;
      margin: 0;
    }
    ion-radio {
      height: 20px;
      width: 20px;
      border: 2px solid var(--theme-one-clr);
      border-radius: 50px;
      margin: 0 10px 0 0;
      .radio-inner {
        width: 28%;
        height: 52%;
      }
    }

    &.item-radio-checked {
      ion-label {
        color: var(--text-black) !important;
      }
      ion-radio {
        background: var(--green-bg);
        --color-checked: var(--white-clr);
      }
    }
  }
  .popover-arrow {
    display: none !important;
  }
  :host::ng-deep ngb-popover-window::ng-deep .arrow {
    display: none !important;
  }
}

.search-bar {
  ion-searchbar {
    .searchbar-input {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

input,
ion-input,
ion-textarea {
  caret-color: var(--text-black);
}

app-bid-payment {
  .input-part {
    ion-input {
      caret-color: var(--white-clr);
    }
    input {
      caret-color: var(--white-clr);
    }
  }
}

.schedule {
  ion-select::part(icon) {
    content: url("./assets/icon/arrow-down-black.svg");
    opacity: 1;
    width: 11px;
    height: 11px;
    margin-left: 5px;
  }
}
app-welcome-screen {
  .swiper-wrapper {
    z-index: 9999;
  }
}

.mat-autocomplete-panel.custom-class {
  max-height: 190px !important;
}

schedule-card {
  &:first-child {
    ion-item-sliding {
      margin-top: 10px;
    }
  }
}

.sq-input-box {
  color: var(--black-clr) !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  border: 1px solid #2099da !important;
  background: transparent !important;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: 6px;
  border-radius: 8px;
  height: 40px;
  margin: 5px 0 5px;
}

.my-custom-class .popover-content {
  background: #000;
  --width: 94% !important;
  left: calc(0px + var(--offset-x, 0px)) !important;
  right: 0 !important;
  
}
.notification-pop {
  --width: calc(100vw - 15px) !important;
  --background: transparent;
  --box-shadow: none;
  --border-radius: 0;
  app-push-notification{padding-top: 3px;}
}

// APP TO WEB CSS START
.slider-data {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 50%;
  display: none;

  .image-part{padding: 0px;position: relative; display: inline-block; border-radius: 0px; width: 100%; height: 100%;
    // img{max-width: 100%;  border-radius: 30px;}
    .video-part-football{ height: 100%;
      iframe{width: 100%; height: 100%;}
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text-part{position: absolute; top: 0px; padding: 40px; height: 100%; display: flex; flex-flow: column; justify-content: space-between; z-index: 2;
      .logo-top{
        img{max-width: 100%;}
      }
      .buttom-text{
        h2{font-size: 3rem; color: var(--white-clr); font-weight: 600; margin: 0 0 10px;
          span{font-weight: 800;}
        }
        p{font-size: 1.375rem; color: var(--white-clr); font-weight: 500; margin: 0 0 10px;}
      }
    }
    .overlay-part{background: rgba(var(--theme-one-bg-rgb), 0.75); position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1; margin: 0; padding: 0;}
  }
}



@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .slider-data .image-part .text-part .buttom-text h2 {
    font-size: 2rem;
    margin: 0 0 6px;
    span {
      font-weight: 800;
    }
  }

  .slider-data .image-part .text-part .buttom-text p {
    font-size: 1rem;
    margin: 0 0 6px;
  }
}

@media screen and (min-width: 1024px) {
  .slider-data {
    display: flex;
  }
  ion-app {
    width: 50%;
    margin: auto 0 auto auto;
    contain: inherit !important;
  }
  ion-loading,
  ion-action-sheet, .notification-pop app-push-notification {
    width: 50%;
    right: 0;
    margin: auto;
    margin-right: 0;
  }
  app-tabs {
    width: 100%;
    ion-router-outlet {
      width: 100%;
    }
  }
}

// APP TO WEB CSS END

.modal-wrapper {
  box-shadow: none;
}

.test-btn {
  text-align: right;
  margin-top: 5px;
  ion-button {
    padding: 5px 7px;
    font-size: 0.875rem;
    color: var(--white-clr);
    background: var(--theme-one-bg);
    margin: 0;
    border-radius: 4px;
  }
}

.meet-up__otp {
  ng-otp-input {
    .optInputContainer {
      display: flex;
      justify-content: center;
      input {
        margin-top: 0px;
        text-align: center;
        padding: 0;
        font-size: 1rem;
        color: var(--text-black);
        background: rgba(var(--white-bg-rgb), 0.7);
        border-radius: 4px;
        border: 1px solid black;
        padding: 5px;
        max-width: 40px;
        width: 100%;
        height: 40px;
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
        &.otp-input.otpInput.ng-valid.ng-dirty.ng-touched {
          border: 1px solid transparent;
        }
        &.otp-input.otpInput.ng-valid.ng-dirty.ng-touched {
          border-color: var(--black-clr);
        }
      }
    }
  }
}
.op-45 {
  opacity: 0.45;
}
.op-65 {
  opacity: 0.65;
}
ion-input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: 0.2;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
}

app-bid-payment {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.datetime-header .datetime-title {
  color: #2099da !important;
  font-size: 1rem !important;
}

app-invoice{
  .radio-box{
    ion-item {
      --inner-border-width: 0;
      background: var(--white-bg);
      --background: var(--white-bg);
      --background: var(--white-bg);
      --ripple-color: none;
      --background-activated: var(--white-bg);
      --background-focused: var(--white-bg);
      --background-hover: var(--white-bg);
      --background-activated-opacity: 1;
      --background-focused-opacity: 1;
      --background-hover-opacity: 1;
      --min-height: 33px;
      --padding-start: 10px;
      --inner-padding-end: 10px;
      ion-label {
        color: var(--text-gray) !important;
        font-size: 0.875rem !important;
        font-weight: 500;
        padding-left: 30px;
        position: absolute;
        margin: 0;
      }
      ion-radio {
        height: 20px;
        width: 20px;
        border: 2px solid var(--theme-one-clr);
        border-radius: 50px;
        margin: 0;
        .radio-inner {
          width: 28%;
          height: 52%;
        }
      }
  
      &.item-radio-checked {
        ion-label {
          color: var(--text-black) !important;
        }
        ion-radio {
          background: var(--green-bg);
          --color-checked: var(--white-clr);
        }
      }
    }
  }
 
}
.plt-ios{
  app-bubble-screen-list{
    ion-content {
      .search-bar{top: 100px !important;}
    }
  }
}
.plt-ipad.plt-ios.plt-tablet{
  app-bubble-screen-list{
    ion-content {
      .search-bar{top: 87px !important;}
    }
  }
}

.notification {
  
  margin-top: 9px !important;
  
  span {
    height: 20px !important;
    width: 20px !important;
    border-radius: 20px !important;
    right: -8px !important;
    top: -9px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    i{display: inline-block;
      font-style: normal;
      text-align: center;
      color: #fff;
      font-size: 0.55rem;}
  }
}
.tabs-btn-main ion-tab-button::part(native){
  overflow: visible;
}
app-help-section{
  .ion-accordion-toggle-icon{margin: 0; --color: var(--text-gray); color: var(--text-gray); font-weight: 400; font-size: 1rem; opacity: 0.5;}
}

.contact-modal {
  --height: auto;
  align-items: center;
  --background: transparent;
  background: transparent;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    border-radius: 12px;
    background: var(--white-bg);
    max-width: 90%;
    margin: 0 auto;
    .inner-content {
      max-height: 70vh;
      overflow: auto;
    }
  }
  ion-footer {
    background: var(--white-bg);
    border-radius: 0;
    ion-toolbar {
      border-radius: 0 !important;
      border: 0;
      background: var(--white-bg);
    }
  }
}

.refresher-md .refresher-pulling-icon, .refresher-md .refresher-refreshing-icon{
  color: var(--white-clr) !important;
  --color: var(--white-clr) !important;
  display: inline-block; width: auto;
  text-align: center; height: 30px; width: 30px; 
  background: rgba(var(--black-bg-rgb), 0.4) !important;
  border-radius: 30px; margin: 0 auto;
  left: 0; right: 0; display: flex; justify-content: center; align-items: center; z-index: 9999;
  top: 0;
}
ion-refresher{z-index: 3;}
// .refresher-md .refresher-pulling-icon{text-align: center; height: 50px; width: 50px; background: var(--black-clr) !important;
//   border-radius: 5px; margin: 0 auto;}


athlete-waitlist{
  .swiper-wrapper{justify-content: center;
  .img-box{text-align: center; margin: 0 auto;}
  }
}

.logo-v{position: relative; display: inline-block;
  .version-txt{  font-size: 0.7rem; color: var(--white-clr); font-weight: 400; position: absolute; right: auto; bottom: -10px; left: 89%; margin: auto; word-wrap: normal; white-space: nowrap;}
  &.tag-logo{
    img{max-width: 168px !important;}
    .version-txt{bottom: 0;}
  }
}


ion-modal.small-modal.modal-class {
  .modal-wrapper {
    max-width: 70%;
  }
  ion-header {
    --background: transparent;
    background: transparent;
    position: relative;
    padding: 0;
    padding: 15px;
    z-index: 5;
    box-shadow: none;
    border: 0;
    text-align: center;

    h3 {
      color: var(--black-clr);
      font-size: 1.25rem;
      font-weight: 700;
      margin: 0;
    }

    &::after {
      display: none;
    }
  }
  .inner-content {
    --background: var(--white-bg);
    .main-screen {
      padding: 0 15px;
      text-align: center;
      p {
        font-size: 0.875rem;
        color: var(--black-clr);
        font-weight: 400;
        margin: 0;
        line-height: 22px;
      }
    }
  }

  ion-footer {
    background: var(--white-bg);
    ion-toolbar {
      --border-width: 0;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-top: 0px;
      --padding-bottom: 10px;
      --background: var(--white-bg);
      text-align: center;
      ion-button {
        --background: transparent;
        --background-focused: transparent;
        --background-hover: transparent;
        --background-activated: transparent;
        --box-shadow: none;
        --padding-start: 0.5rem;
        --padding-end: 0.5rem;
        img {
          width: 40px;
          height: 40px;
        }
        &.button-disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
.login-modal {
  // [DK, MP] Fix login modal height issue on android and ios
  // =======================================================
  // height: calc(100vh - 170px);
  // =======================================================
  background-color: #2099DA;
  // background-color:  #2099DA;
}
.loginModalKeyboardOpen {
  // [DK, MP] Fix login modal height issue on android and ios
  // =======================================================
  // height: calc(100vh - 400px) !important;
  height: fit-content;
  // =======================================================
  background-color: #2099DA;
}
// [DK, MP] Fix login modal height issue on android and ios
// =======================================================
.login-modal-content-height {
  height: 45vh;
}
.login-modal-content-height-kb-active {
  height: 28vh;
}
.login-modal-content-height-android {
  height: 45vh;
}
// =======================================================
.custom-class.new{
 .mdc-list-item__primary-text{display: block; width: 100%;}
}